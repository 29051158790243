@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --p: 0 89% 69%;
  --pf: 0 73% 65%;
  --pc: 0 0% 100%;
  --s: 201 55% 78%;
  --sf: 200 43% 73%;
  --sc: 200 28% 63%;
  --a: 50 86% 80%;
  --af: 50 62% 74%;
  --ac: 50 20% 47%;
  --n: 216 5% 50%;
  --nf: 228 41% 36%;
  --nc: 0 0% 100%;
  --b1: 0 0% 95%;
  --b2: 0 0% 83%;
  --b3: 0 0% 60%;
  --bc: 0 0% 45%;
  --in: 207 76% 65%;
  --su: 174 47% 48%;
  --wa: 50 86% 80%;
  --er: 354 90% 66%;

  --padding-card: 1.25rem;
}

@import 'custom.css';
